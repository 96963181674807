(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.dialogs.enterPasswordDialogSkinMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_content",
        "_responsive",
        "_material-design"
      ],
      {},
      [
        "div",
        "title",
        [],
        {}
      ],
      [
        "div",
        "wrapper",
        [
          "_wrapper"
        ],
        {},
        [
          "div",
          "header",
          [],
          {},
          [
            "div",
            "subtitle",
            [],
            {}
          ]
        ],
        [
          "div",
          "content",
          [],
          {},
          [
            "div",
            "password",
            [],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_footer"
          ],
          {},
          [
            "p",
            "errMsg",
            [],
            {}
          ],
          [
            "button",
            "okButton",
            [],
            {
              "role": "button"
            }
          ]
        ]
      ]
    ],
    [
      "div",
      "xButton",
      [],
      {
        "role": "button",
        "tabIndex": 0,
        "aria-label": "X"
      },
      [
        "svg",
        null,
        [],
        {
          "width": "26px",
          "height": "26px",
          "viewBox": "0 0 26 26"
        },
        [
          "g",
          null,
          [],
          {
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          },
          [
            "g",
            null,
            [],
            {
              "transform": "translate(-1829.000000, -60.000000)",
              "fill": "#2F2E2E"
            },
            [
              "g",
              null,
              [],
              {
                "transform": "translate(1829.000000, 60.000000)"
              },
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ],
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(-45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;width:100%;padding-bottom:40px;text-align:left;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;padding-bottom:40px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;width:390px;padding:0;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%header": "line-height:30px;",
    "%[data-state~=\"mobile\"] %header": "line-height:30px;",
    "%[data-state~=\"mobile\"] %favIconImg": "display:none;",
    "%[data-state~=\"mobile\"] %content": "width:auto;",
    "%[data-state~=\"mobile\"] %_footer": "margin-top:0;padding-bottom:10px;",
    "%[data-state~=\"cancelHidden\"] %cancel": "display:none;",
    "%content": "width:100%;",
    "%inputWithValidationinput": "padding:0;",
    "%password label": "display:none;",
    "%_rtl": "direction:rtl;",
    "%_ltr": "direction:ltr;"
  }
}
 skins['wysiwyg.viewer.skins.InputWithValidationSkin'] = {
  "react": [
    [
      "label",
      "label",
      [],
      {}
    ],
    [
      "input",
      "input",
      [],
      {}
    ],
    [
      "label",
      "lowerLabel",
      [],
      {}
    ],
    [
      "p",
      "errorMessage",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL",
    "clr": "COLOR"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15"
  },
  "css": {
    "%": "font-size:1em;",
    "%label": "float:none;font-size:17px;line-height:25px;color:#585858;",
    "%input": "width:100%;font-size:19px;line-height:42px;color:#0198ff;margin:0 -3px;box-sizing:border-box;",
    "%input%_mobile label": "font-size:14px;line-height:20px;",
    "%input:not(%_new)": "height:42px;padding:0 15px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -170px;border:solid 1px #a1a1a1;",
    "%input:not(%_new)%_error": "border-color:#d74401;",
    "%input%_new": "height:48px;font-size:14px;font-family:Helvetica;font-weight:300;color:[clr];line-height:14px;padding:0 12px;",
    "%input%_new::-webkit-input-placeholder": "color:[clr];letter-spacing:0;",
    "%input%_new::-ms-input-placeholder": "color:[clr];letter-spacing:0;",
    "%input%_new::placeholder": "color:[clr];letter-spacing:0;",
    "%input%_new%_error": "border:1px solid #ff3d3d;",
    "%input%_new%_mobile": "height:40px;font-size:14px;padding:0;",
    "%input%_new%_mobile%_error": "border-bottom:1px solid #ff3d3d;",
    "%input[type=\"password\"]": "font-size:38px;",
    "%input[type=\"password\"]%_new": "font-size:14px;",
    "%input%_mobile%_new": "font-size:14px;",
    "%input%_mobile[type=\"password\"]": "font-size:14px;",
    "%input%_mobile[type=\"password\"]%_new": "font-size:14px;",
    "%errorMessage": "display:block;font-size:12px;color:#d74401;text-align:right;height:15px;",
    "%errorMessage%_new": "font-family:Helvetica;color:#ff3d3d;font-size:12px;    line-height:24px;    font-weight:300;text-align:initial;padding-bottom:24px;height:0;",
    "%errorMessage%_new%_mobile": "font-size:10px;"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.notificationDialogSkinMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_material-design"
      ],
      {},
      [
        "p",
        "dialogTitle",
        [],
        {}
      ],
      [
        "p",
        "dialogDescription",
        [],
        {}
      ],
      [
        "button",
        "okButton",
        [],
        {}
      ]
    ],
    [
      "div",
      "xButton",
      [],
      {
        "role": "button",
        "tabIndex": 0,
        "aria-label": "X"
      },
      [
        "svg",
        null,
        [],
        {
          "width": "26px",
          "height": "26px",
          "viewBox": "0 0 26 26"
        },
        [
          "g",
          null,
          [],
          {
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          },
          [
            "g",
            null,
            [],
            {
              "transform": "translate(-1829.000000, -60.000000)",
              "fill": "#2F2E2E"
            },
            [
              "g",
              null,
              [],
              {
                "transform": "translate(1829.000000, 60.000000)"
              },
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ],
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(-45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;line-height:30px;padding-bottom:0;margin-top:0;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;font-size:14px;line-height:22px;margin-top:18px;padding-bottom:0;width:85%;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;margin-top:20px;height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%dialog %dialogTitle": "[headingL]    font-size:48px;text-align:center;line-height:50px;padding-bottom:20px;",
    "%dialog %dialogDescription": "[fnt]    overflow:hidden;text-overflow:ellipsis;font-size:16px;font-weight:300;line-height:24px;display:inline-block;",
    "%dialog %dialogDescription%_noDialogDescription": "display:none;",
    "%[data-state~=\"desktop\"] %dialogTitle": "width:580px;padding:10px 10px 0 10px;margin-bottom:20px;",
    "%[data-state~=\"desktop\"] %dialogDescription": "width:570px;padding-bottom:28px;",
    "%[data-state~=\"desktop\"] %okButton": "width:285px;margin-top:10px;"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_container",
        "_material-design"
      ],
      {},
      [
        "div",
        null,
        [
          "_contentWrapper"
        ],
        {},
        [
          "h1",
          "title",
          [
            "_title"
          ],
          {}
        ],
        [
          "div",
          "content",
          [
            "_body"
          ],
          {},
          [
            "div",
            "thanksTxt",
            [],
            {}
          ],
          [
            "div",
            "clickTheLinkTxt",
            [],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_my-footer"
          ],
          {},
          [
            "div",
            "didntGetTxt",
            [],
            {}
          ],
          [
            "div",
            "checkSpamTxt",
            [
              "_linkPrefix"
            ],
            {}
          ],
          [
            "a",
            "resendLink",
            [
              "_link"
            ],
            {
              "tabIndex": 0
            }
          ]
        ]
      ]
    ],
    [
      "div",
      "xButton",
      [],
      {
        "role": "button",
        "tabIndex": 0,
        "aria-label": "X"
      },
      [
        "svg",
        null,
        [],
        {
          "width": "26px",
          "height": "26px",
          "viewBox": "0 0 26 26"
        },
        [
          "g",
          null,
          [],
          {
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          },
          [
            "g",
            null,
            [],
            {
              "transform": "translate(-1829.000000, -60.000000)",
              "fill": "#2F2E2E"
            },
            [
              "g",
              null,
              [],
              {
                "transform": "translate(1829.000000, 60.000000)"
              },
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ],
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(-45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "defClr": "COLOR",
    "ttlFnt": "FONT",
    "cntFnt": "FONT",
    "ftrFnt": "FONT",
    "lnkClr": "COLOR",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "defClr": "color_15",
    "ttlFnt": "font_5",
    "cntFnt": "font_7",
    "ftrFnt": "font_7",
    "lnkClr": "color_18",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%_container": "height:275px;width:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;text-align:center;",
    "%_contentWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;color:[defClr];",
    "%_contentWrapper %_title": "[ttlFnt]",
    "%_contentWrapper %_body": "[cntFnt]",
    "%_my-footer": "[ftrFnt]  margin:0 auto;overflow:hidden;position:relative;-webkit-animation:opacity-kf 1.8s;animation:opacity-kf 1.8s;",
    "%_my-footer %_link": "color:[lnkClr];text-decoration:underline;",
    "%_my-footer %_link:hover": "opacity:0.8;color:[lnkClr];",
    "%_my-footer %_link:active": "opacity:0.6;color:[lnkClr];",
    "%_my-footer %_link:visited": "color:[lnkClr];",
    "%[data-state~=\"desktop\"] %_contentWrapper": "max-width:518px;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_title": "font-size:32px;line-height:48px;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_body": "margin:21px 0 80px;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_body,%[data-state~=\"desktop\"] %_contentWrapper %_my-footer": "font-size:18px;line-height:30px;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_linkPrefix": "display:inline;",
    "%[data-state~=\"mobile\"] %_contentWrapper": "max-width:280px;",
    "%[data-state~=\"mobile\"] %_contentWrapper %_title": "font-size:24px;line-height:34px;",
    "%[data-state~=\"mobile\"] %_contentWrapper %_body": "margin:18px 0 28px;",
    "%[data-state~=\"mobile\"] %_contentWrapper %_body,%[data-state~=\"mobile\"] %_contentWrapper %_my-footer": "font-size:13px;line-height:22px;",
    "@-webkit-keyframes %opacity-kf": "0%{opacity:0;}100%{opacity:1;}",
    "@keyframes %opacity-kf": "0%{opacity:0;}100%{opacity:1;}"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_material-design"
      ],
      {},
      [
        "h1",
        "title",
        [],
        {}
      ],
      [
        "div",
        "contentWrapper",
        [
          "_wrapper"
        ],
        {},
        [
          "div",
          null,
          [
            "_switchToSignUpContainer"
          ],
          {},
          [
            "span",
            "switchToSignUpText",
            [],
            {}
          ],
          [
            "a",
            "switchDialogLink",
            [],
            {
              "role": "link",
              "tabIndex": 0
            }
          ]
        ],
        [
          "div",
          null,
          [
            "_customLogin"
          ],
          {},
          [
            "div",
            "content",
            [],
            {},
            [
              "div",
              "email",
              [],
              {}
            ],
            [
              "div",
              "password",
              [],
              {}
            ]
          ],
          [
            "div",
            null,
            [
              "_switchLinkContainer"
            ],
            {},
            [
              "div",
              "forgotYourPasswordLink",
              [],
              {
                "role": "link",
                "tabIndex": 0
              }
            ]
          ],
          [
            "button",
            "okButton",
            [],
            {}
          ]
        ],
        [
          "div",
          "socialSectionDivider",
          [
            "_sectionDivider"
          ],
          {},
          [
            "span",
            "socialSectionDividerText",
            [],
            {}
          ]
        ],
        [
          "div",
          "socialLoginWrapper",
          [],
          {},
          [
            "iframe",
            "socialLoginIframe",
            [],
            {}
          ],
          [
            "iframe",
            "socialLoginIframeHorizontal",
            [],
            {}
          ],
          [
            "div",
            "socialLoginLoader",
            [],
            {}
          ],
          [
            "p",
            "socialLoginErrorMsg",
            [],
            {}
          ]
        ],
        [
          "div",
          "emailSectionDivider",
          [
            "_sectionDivider"
          ],
          {},
          [
            "span",
            "emailSectionDividerText",
            [],
            {}
          ]
        ],
        [
          "button",
          "switchToEmailLink",
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      "preloader",
      [],
      {}
    ],
    [
      "div",
      "xButton",
      [],
      {
        "role": "button",
        "tabIndex": 0,
        "aria-label": "X"
      },
      [
        "svg",
        null,
        [],
        {
          "width": "26px",
          "height": "26px",
          "viewBox": "0 0 26 26"
        },
        [
          "g",
          null,
          [],
          {
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          },
          [
            "g",
            null,
            [],
            {
              "transform": "translate(-1829.000000, -60.000000)",
              "fill": "#2F2E2E"
            },
            [
              "g",
              null,
              [],
              {
                "transform": "translate(1829.000000, 60.000000)"
              },
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ],
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(-45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);position:fixed;",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%[data-state~=\"desktop\"] %_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%[data-state~=\"desktop\"] %_material-design %switchToSignUpText": "[fnt]  font-size:18px;margin-right:4px;margin-left:4px;",
    "%[data-state~=\"desktop\"] %_material-design %switchDialogLink": "[fnt]  color:[txt];font-size:18px;float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %_material-design %_switchToSignUpContainer": "text-align:center;padding-top:0 !important;margin-bottom:60px;",
    "%[data-state~=\"desktop\"] %_material-design %rememberMeCheckbox": "margin-right:10px;",
    "%[data-state~=\"mobile\"] %_material-design %subtitle": "margin-top:0;padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %_material-design %switchToSignUpText": "[fnt]  font-size:15px;margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %_material-design %switchDialogLink": "[fnt]  color:[txt];font-size:15px;float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %_material-design %_switchToSignUpContainer": "text-align:center;padding-top:0 !important;margin-bottom:35px;",
    "%[data-state~=\"mobile\"] %_material-design %_emailLoginMode %socialSectionDivider": "margin:0 auto 30px;",
    "%[data-state~=\"mobile\"] %_material-design %rememberMeCheckboxLabel": "font-size:15px !important;",
    "%[data-state~=\"mobile\"] %_material-design %forgotYourPasswordLink": "font-size:15px !important;margin-top:-2px;",
    "%_material-design %forgotYourPasswordLink": "[fnt]  font-size:16px;line-height:17px;text-decoration:underline;cursor:pointer;opacity:1;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %forgotYourPasswordLink:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %forgotYourPasswordLinkMobile": "display:none;",
    "%_material-design %_switchLinkContainer": "margin-top:20px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;padding-top:0;padding-bottom:18px;",
    "%_material-design %memberLoginDialoginfoTitle": "margin-bottom:0;margin-top:0;",
    "%_material-design %content": "margin-bottom:38px;",
    "%_material-design %okButton": "padding-bottom:0;margin-bottom:30px;",
    "%_material-design %_socialLoginMode %_customLogin": "display:none;",
    "%_material-design %_socialLoginMode %socialSectionDivider": "display:none;",
    "%_material-design %_socialLoginMode %switchToEmailLink,%_material-design %_socialLoginMode %emailSectionDivider": "display:block;",
    "%_material-design %socialLoginWrapper": "margin-bottom:0 !important;",
    "%_material-design %_emailLoginMode %socialSectionDivider": "display:block !important;margin:0 auto 45px;",
    "%_material-design %_emailLoginMode %socialLoginWrapper": "display:block;",
    "%_material-design %_emailLoginMode %_switchToSignUpContainer": "margin-bottom:70px;",
    "%[data-state~=\"desktop\"] %_material-design %socialLoginWrapper": "margin-top:0;margin-bottom:0;",
    "%[data-state~=\"desktop\"] %_material-design %switchToEmailLink": "width:320px;margin:auto;"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.noPermissionsToPageMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_material-design"
      ],
      {},
      [
        "h3",
        "title",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_wrapper"
        ],
        {},
        [
          "div",
          "content",
          [],
          {}
        ],
        [
          "div",
          null,
          [
            "_footer"
          ],
          {},
          [
            "button",
            "okButton",
            [],
            {}
          ],
          [
            "a",
            "switchAccountLink",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "ttlFnt": "FONT",
    "defClr": "COLOR",
    "btnFnt": "FONT",
    "cntFnt": "FONT",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "ttlFnt": "font_5",
    "defClr": "color_15",
    "btnFnt": "font_8",
    "cntFnt": "font_7",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);width:280px;left:calc(50% - 140px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%dialog %title": "[ttlFnt]  font-size:36px;line-height:48px;",
    "%dialog %_wrapper": "max-width:518px;color:[defClr];",
    "%dialog %_wrapper %content": "margin-top:24px;margin-bottom:60px;",
    "%dialog %_wrapper %_footer %okButton": "[btnFnt]",
    "%dialog %_wrapper %_footer %switchAccountLink": "[cntFnt]    color:[defClr];text-decoration:underline;font-size:18px;line-height:30px;margin-top:24px;display:inline-block;",
    "%dialog %_wrapper %_footer %switchAccountLink:hover": "opacity:0.8;color:[defClr];",
    "%dialog %_wrapper %_footer %switchAccountLink:active": "opacity:0.6;color:[defClr];",
    "%dialog %_wrapper %_footer %switchAccountLink:visited": "color:[defClr];",
    "%[data-state~=\"mobile\"] %dialog %title": "font-size:24px;line-height:34px;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper": "width:280px;padding:0;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper %content": "margin-top:18px;margin-bottom:60px;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper %_footer %switchAccountLink": "font-size:13px;line-height:22px;margin-top:14px;"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.requestPasswordResetDialogSkinMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_material-design"
      ],
      {},
      [
        "h3",
        "title",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_wrapper"
        ],
        {},
        [
          "div",
          null,
          [
            "_header"
          ],
          {},
          [
            "div",
            "subtitle",
            [],
            {}
          ]
        ],
        [
          "div",
          "content",
          [],
          {},
          [
            "div",
            "email",
            [],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_footer"
          ],
          {},
          [
            "p",
            "errMsg",
            [],
            {}
          ],
          [
            "button",
            "okButton",
            [],
            {}
          ]
        ]
      ]
    ],
    [
      "div",
      "xButton",
      [],
      {
        "role": "button",
        "tabIndex": 0
      },
      [
        "svg",
        null,
        [],
        {
          "width": "26px",
          "height": "26px",
          "viewBox": "0 0 26 26"
        },
        [
          "g",
          null,
          [],
          {
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          },
          [
            "g",
            null,
            [],
            {
              "transform": "translate(-1829.000000, -60.000000)",
              "fill": "#2F2E2E"
            },
            [
              "g",
              null,
              [],
              {
                "transform": "translate(1829.000000, 60.000000)"
              },
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ],
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(-45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%[data-state~=\"desktop\"] %_material-design %subtitle": "[fnt]  font-size:18px;",
    "%[data-state~=\"mobile\"] %_material-design %subtitle": "[fnt]  font-size:15px;",
    "%_material-design %_footer": "text-align:right;width:100%;padding-bottom:10px;",
    "%[data-state~=\"desktop\"] %_material-design %_wrapper": "width:320px !important;"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.resetPasswordDialogSkinMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_material-design"
      ],
      {},
      [
        "h3",
        "title",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_wrapper"
        ],
        {},
        [
          "div",
          null,
          [
            "_header"
          ],
          {},
          [
            "p",
            "subtitle",
            [],
            {}
          ]
        ],
        [
          "div",
          "content",
          [],
          {},
          [
            "div",
            "password",
            [],
            {}
          ],
          [
            "div",
            "retypePassword",
            [],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_footer"
          ],
          {},
          [
            "p",
            "errMsg",
            [],
            {}
          ],
          [
            "button",
            "okButton",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%_material-design%dialog %subtitle": "[fnt]  font-size:18px;margin-top:0;padding-bottom:60px;",
    "%_material-design %_footer": "text-align:right;width:100%;padding-bottom:10px;",
    "%[data-state~=\"desktop\"] %_material-design %_wrapper": "width:320px !important;"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.sentConfirmationEmailMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "xButton",
      [],
      {
        "role": "button",
        "tabIndex": 0,
        "aria-label": "X"
      },
      [
        "svg",
        null,
        [],
        {
          "width": "26px",
          "height": "26px",
          "viewBox": "0 0 26 26"
        },
        [
          "g",
          null,
          [],
          {
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          },
          [
            "g",
            null,
            [],
            {
              "transform": "translate(-1829.000000, -60.000000)",
              "fill": "#2F2E2E"
            },
            [
              "g",
              null,
              [],
              {
                "transform": "translate(1829.000000, 60.000000)"
              },
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ],
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(-45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ]
            ]
          ]
        ]
      ]
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_container",
        "_material-design"
      ],
      {},
      [
        "div",
        null,
        [
          "_contentWrapper"
        ],
        {},
        [
          "h3",
          "sentEmail",
          [
            "_title"
          ],
          {}
        ],
        [
          "h3",
          "checkInbox",
          [
            "_title"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_footer"
          ],
          {},
          [
            "div",
            "didntGetEmail",
            [],
            {}
          ],
          [
            "div",
            "checkSpam",
            [
              "_linkPrefix"
            ],
            {}
          ],
          [
            "a",
            "resendLink",
            [
              "_link"
            ],
            {
              "tabIndex": 0
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "defClr": "COLOR",
    "ttlFnt": "FONT",
    "cntFnt": "FONT",
    "ftrFnt": "FONT",
    "lnkClr": "COLOR",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "defClr": "color_15",
    "ttlFnt": "font_5",
    "cntFnt": "font_7",
    "ftrFnt": "font_7",
    "lnkClr": "color_18",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;[ftrFnt]  margin:0 auto;overflow:hidden;-webkit-animation:opacity-kf 1.8s;animation:opacity-kf 1.8s;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%_container": "height:275px;width:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;text-align:center;",
    "%_contentWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;color:[defClr];",
    "%_contentWrapper %_title": "[ttlFnt]",
    "%_contentWrapper %_body": "[cntFnt]",
    "%_footer %_link": "color:[lnkClr];text-decoration:underline;",
    "%_footer %_link:hover": "opacity:0.8;color:[lnkClr];",
    "%_footer %_link:active": "opacity:0.6;color:[lnkClr];",
    "%_footer %_link:visited": "color:[lnkClr];",
    "%[data-state~=\"desktop\"] %_contentWrapper": "max-width:518px;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_title": "font-size:32px;line-height:48px;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_body": "margin:21px 0 80px;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_body,%[data-state~=\"desktop\"] %_contentWrapper %_footer": "font-size:18px;line-height:30px;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_linkPrefix": "display:inline;",
    "%[data-state~=\"desktop\"] %_contentWrapper %_footer": "margin-top:80px;",
    "%[data-state~=\"mobile\"] %_contentWrapper": "max-width:210px;",
    "%[data-state~=\"mobile\"] %_contentWrapper %_title": "font-size:24px;line-height:34px;",
    "%[data-state~=\"mobile\"] %_contentWrapper %_body": "margin:18px 0 28px;",
    "%[data-state~=\"mobile\"] %_contentWrapper %_body,%[data-state~=\"mobile\"] %_contentWrapper %_footer": "font-size:13px;line-height:22px;",
    "%[data-state~=\"mobile\"] %_contentWrapper %_footer": "margin-top:28px;",
    "@-webkit-keyframes %opacity-kf": "0%{opacity:0;}100%{opacity:1;}",
    "@keyframes %opacity-kf": "0%{opacity:0;}100%{opacity:1;}"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_material-design"
      ],
      {},
      [
        "h1",
        "title",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_switchLinkContainer"
        ],
        {},
        [
          "p",
          "note",
          [],
          {}
        ],
        [
          "a",
          "switchDialogLink",
          [],
          {
            "tabIndex": 0
          }
        ]
      ],
      [
        "div",
        "contentWrapper",
        [
          "_wrapper"
        ],
        {},
        [
          "div",
          null,
          [
            "_customLogin"
          ],
          {},
          [
            "div",
            "content",
            [],
            {},
            [
              "div",
              "email",
              [],
              {}
            ],
            [
              "p",
              "errMsg",
              [],
              {}
            ],
            [
              "div",
              "password",
              [],
              {}
            ]
          ],
          [
            "div",
            null,
            [
              "_footer"
            ],
            {},
            [
              "button",
              "okButton",
              [],
              {}
            ]
          ]
        ],
        [
          "div",
          "socialSectionDivider",
          [
            "_sectionDivider"
          ],
          {},
          [
            "span",
            "socialSectionDividerText",
            [],
            {}
          ]
        ],
        [
          "div",
          "socialLoginWrapper",
          [],
          {},
          [
            "iframe",
            "socialLoginIframe",
            [],
            {}
          ],
          [
            "iframe",
            "socialLoginIframeHorizontal",
            [],
            {}
          ],
          [
            "div",
            "socialLoginLoader",
            [],
            {}
          ],
          [
            "p",
            "socialLoginErrorMsg",
            [],
            {}
          ]
        ],
        [
          "div",
          "emailSectionDivider",
          [
            "_sectionDivider"
          ],
          {},
          [
            "span",
            "emailSectionDividerText",
            [],
            {}
          ]
        ],
        [
          "button",
          "switchToEmailLink",
          [],
          {}
        ]
      ],
      [
        "div",
        "socialFooter",
        [
          "_form-footer"
        ],
        {},
        [
          "div",
          "communityCheckboxWrapper",
          [
            "_community-checkbox-wrapper"
          ],
          {},
          [
            "input",
            "joinCommunityByDefaultCheckbox",
            [],
            {
              "type": "checkbox"
            }
          ],
          [
            "label",
            null,
            [
              "_checked"
            ],
            {
              "aria-hidden": true
            },
            [
              "svg",
              null,
              [
                "_checkbox"
              ],
              {
                "height": "15",
                "width": "15",
                "x": "0px",
                "y": "0px",
                "viewBox": "0 0 15 15"
              },
              [
                "rect",
                null,
                [
                  "_checkbox-bg"
                ],
                {
                  "height": "15",
                  "width": "15"
                }
              ],
              [
                "polygon",
                null,
                [
                  "_checkbox-mark"
                ],
                {
                  "points": "6.3,11.1 2.8,7.6 3.7,6.7 6.3,9.3 11.7,4 12.5,4.9"
                }
              ]
            ]
          ],
          [
            "label",
            null,
            [
              "_unchecked"
            ],
            {
              "aria-hidden": true
            },
            [
              "svg",
              null,
              [
                "_checkbox"
              ],
              {
                "height": "15",
                "width": "15",
                "x": "0px",
                "y": "0px",
                "viewBox": "0 0 15 15"
              },
              [
                "rect",
                null,
                [
                  "_checkbox-bg"
                ],
                {
                  "height": "15",
                  "width": "15"
                }
              ]
            ]
          ],
          [
            "p",
            "joinCommunityByDefaultCheckboxLabel",
            [],
            {}
          ],
          [
            "a",
            "joinCommunityReadMoreLink",
            [
              "_dialog-link"
            ],
            {
              "role": "link"
            }
          ]
        ],
        [
          "div",
          "joinCommunityReadMoreWrapper",
          [],
          {},
          [
            "p",
            "joinCommunityReadMoreParagraph",
            [],
            {},
            [
              "span",
              "joinCommunityReadMoreContent",
              [],
              {}
            ],
            [
              "span",
              "codeOfConductLink",
              [
                "_dialog-link"
              ],
              {
                "role": "link"
              }
            ]
          ]
        ],
        [
          "div",
          "policiesFooter",
          [],
          {},
          [
            "p",
            "policiesText",
            [],
            {},
            [
              "span",
              "signupAgreement",
              [],
              {}
            ],
            [
              "span",
              "termsOfUseLink",
              [
                "_dialog-link"
              ],
              {
                "role": "link"
              }
            ],
            [
              "span",
              "andElement",
              [],
              {}
            ],
            [
              "span",
              "privacyPolicyLink",
              [
                "_dialog-link"
              ],
              {
                "role": "link"
              }
            ]
          ]
        ]
      ]
    ],
    [
      "div",
      "preloader",
      [],
      {}
    ],
    [
      "div",
      "xButton",
      [],
      {
        "role": "button",
        "tabIndex": 0
      },
      [
        "svg",
        null,
        [],
        {
          "width": "26px",
          "height": "26px",
          "viewBox": "0 0 26 26"
        },
        [
          "g",
          null,
          [],
          {
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          },
          [
            "g",
            null,
            [],
            {
              "transform": "translate(-1829.000000, -60.000000)",
              "fill": "#2F2E2E"
            },
            [
              "g",
              null,
              [],
              {
                "transform": "translate(1829.000000, 60.000000)"
              },
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ],
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(-45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;margin-top:5px !important;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;margin-top:-50px;bottom:inherit;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%[data-state~=\"desktop\"] %_material-design %_switchLinkContainer": "padding-top:0;margin-bottom:60px;",
    "%[data-state~=\"desktop\"] %_material-design %note": "[fnt]  font-size:18px;",
    "%[data-state~=\"desktop\"] %_material-design %switchDialogLink": "[fnt]  color:[txt];font-size:18px;float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %_material-design %_switchLinkContainer": "padding-top:0;margin-bottom:35px;",
    "%[data-state~=\"mobile\"] %_material-design %note": "[fnt]  font-size:15px;",
    "%[data-state~=\"mobile\"] %_material-design %switchDialogLink": "[fnt]  color:[txt];font-size:15px;float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %_material-design %_emailLoginMode %socialSectionDivider": "margin:0 auto 30px;",
    "%_material-design %note": "text-align:center;float:none;display:inline;",
    "%_material-design %_footer": "padding-bottom:0 !important;margin-bottom:30px;",
    "%_material-design %_socialLoginMode %_customLogin,%_material-design %_socialLoginMode %socialSectionDivider": "display:none;",
    "%_material-design %_socialLoginMode %switchToEmailLink,%_material-design %_socialLoginMode %emailSectionDivider": "display:block;",
    "%_material-design %socialLoginWrapper": "margin-bottom:0 !important;",
    "%_material-design %_emailLoginMode %socialSectionDivider": "display:block;margin:0 auto 45px;",
    "%[data-state~=\"desktop\"] %_material-design %socialLoginWrapper": "margin-top:0;margin-bottom:0;",
    "%[data-state~=\"desktop\"] %_material-design %switchToEmailLink": "width:320px;margin:auto auto 10px;",
    "%[data-state~=\"desktop\"] %joinCommunityReadMoreWrapper": "width:540px;margin:auto auto 15px;",
    "%[data-state~=\"desktop\"] %policiesText": "width:540px;margin:auto;",
    "%joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%joinCommunityReadMoreLink": "margin-left:4px;margin-right:4px;",
    "%signupAgreement": "margin-right:4px;",
    "%codeOfConductLink": "margin-left:4px;",
    "%andElement": "margin-right:4px;margin-left:4px;",
    "%communityCheckboxWrapper": "position:relative;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;-webkit-align-self:center;align-self:center;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "margin-top:5px;width:12px;opacity:0;cursor:pointer;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox[direction=\"rtl\"]": "float:right !important;margin-left:10px !important;margin-right:0 !important;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox[direction=\"rtl\"] ~ label %_checkbox": "right:0 !important;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not([direction=\"rtl\"])": "float:left;margin-right:10px;margin-left:0;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not([direction=\"rtl\"]) ~ label %_checkbox": "left:0;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%communityCheckboxWrapper %joinCommunityByDefaultCheckbox:focus ~ label %_checkbox": "box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.9), 0 0 1px 2px #3899ec !important;",
    "%_form-footer": "[fnt]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:40px;font-size:15px;line-height:1.6;text-align:center;color:[clr];font-weight:300;",
    "%_form-footer %_dialog-link": "font-weight:300;text-decoration:underline;cursor:pointer;opacity:1;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_form-footer %_dialog-link:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinMaterial'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "dialog",
      [
        "_responsive",
        "_material-design"
      ],
      {},
      [
        "h3",
        "title",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_wrapper"
        ],
        {},
        [
          "div",
          "content",
          [],
          {},
          [
            "div",
            "someInfoTxt",
            [],
            {}
          ],
          [
            "div",
            "checkTxt",
            [],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_footer"
          ],
          {},
          [
            "button",
            "okButton",
            [],
            {}
          ],
          [
            "a",
            "backToSiteLink",
            [],
            {
              "role": "link",
              "tabIndex": 0
            }
          ]
        ]
      ]
    ],
    [
      "div",
      "xButton",
      [],
      {
        "role": "button",
        "tabIndex": 0,
        "aria-label": "X"
      },
      [
        "svg",
        null,
        [],
        {
          "width": "26px",
          "height": "26px",
          "viewBox": "0 0 26 26"
        },
        [
          "g",
          null,
          [],
          {
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          },
          [
            "g",
            null,
            [],
            {
              "transform": "translate(-1829.000000, -60.000000)",
              "fill": "#2F2E2E"
            },
            [
              "g",
              null,
              [],
              {
                "transform": "translate(1829.000000, 60.000000)"
              },
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ],
              [
                "rect",
                null,
                [],
                {
                  "transform": "translate(13.227922, 12.727922) rotate(-45.000000) translate(-13.227922, -12.727922) ",
                  "x": "12.7279221",
                  "y": "-4.27207794",
                  "width": "1",
                  "height": "34"
                }
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    },
    "okButton": {
      "skin": "wysiwyg.viewer.skins.button.BasicButton",
      "styleGroup": "b1"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "fnt": "FONT",
    "bgc": "BG_COLOR_ALPHA",
    "brd2": "BORDER_COLOR",
    "btn_txt": "COLOR",
    "brd": "BORDER_COLOR_ALPHA",
    "brd_h": "BORDER_COLOR",
    "txt2": "COLOR",
    "ttlFnt": "FONT",
    "defClr": "COLOR",
    "cntFnt": "FONT",
    "btnFnt": "FONT",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "color_18",
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_2",
    "fnt": "font_8",
    "bgc": "color_18",
    "brd2": "color_13",
    "btn_txt": "color_15",
    "brd": "color_13",
    "brd_h": "color_15",
    "txt2": "color_14",
    "ttlFnt": "font_5",
    "defClr": "color_15",
    "cntFnt": "font_7",
    "btnFnt": "font_8",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);width:280px;left:calc(50% - 140px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;top:calc(50% - 120px);",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;top:24px;right:24px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;width:16px;height:16px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg g": "fill:[clr];",
    "%[data-state~=\"desktop\"] %dialog%_material-design %submitButton,%[data-state~=\"desktop\"] %dialog%_material-design %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %_wrapper": "width:auto;max-width:450px;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper": "width:auto;max-width:800px;display:inline-block !important;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %_customLogin": "width:320px;margin:auto;",
    "%[data-state~=\"desktop\"] %dialog%_material-design %contentWrapper %socialLoginWrapper": "width:320px;margin:auto;",
    "%_material-design %submitButton,%_material-design %okButton": "text-decoration:none !important;font-size:16px !important;color:#ffffff;height:48px;width:100%;background-color:[bgc];font-weight:300;cursor:pointer;border-radius:1px;opacity:1;-webkit-transform:translateZ(0);transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;height:48px !important;border-radius:1px;",
    "%_material-design %submitButton:hover,%_material-design %okButton:hover": "opacity:0.8;transition:opacity .2s ease-in-out;-moz-transition:opacity .2s ease-in-out;-webkit-transition:opacity .2s ease-in-out;",
    "%_material-design %subtitle": "margin-top:0;padding-bottom:60px;",
    "%_material-design %contentWrapper %socialLoginIframe": "-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper %socialLoginIframeHorizontal": "width:100%;-webkit-transition:opacity ease-in 0.2s;transition:opacity ease-in 0.2s;",
    "%_material-design %contentWrapper%_vertical %socialSectionDivider": "display:none;",
    "%_material-design %errMsg": "font-family:Avenir;position:absolute;bottom:90px;",
    "%_material-design %errMsg,%_material-design %_errorMessage": "height:auto;min-height:24px;padding-bottom:0;",
    "%_material-design %socialLoginErrorMsg": "margin-bottom:30px;",
    "%_material-design %dialogTitle,%_material-design %title": "[headingL]  font-size:48px;line-height:.8em;margin-bottom:23px;margin-top:10px;padding-top:0;padding-bottom:0;",
    "%_material-design %_sectionDivider": "width:320px;text-align:center;margin:0 auto 40px;height:11px;border-bottom:1px solid;border-color:[brd2];",
    "%_material-design %_sectionDivider span": "[fnt]    font-size:16px;color:[clr];background-color:[bg];padding:0 13px;",
    "%_material-design %emailSectionDivider": "display:none;",
    "%_material-design %_wrapper %switchToEmailLink": "display:none;[fnt]  color:[btn_txt];font-size:16px;text-decoration:none !important;height:48px;width:100%;border:1px solid [brd];font-weight:300;cursor:pointer;border-radius:1px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %_wrapper %switchToEmailLink:hover": "border:1px solid [brd_h];transition:border .2s ease-in-out;-moz-transition:border .2s ease-in-out;-webkit-transition:border .2s ease-in-out;",
    "%_material-design%_rtl %email label,%_material-design%_rtl %password label,%_material-design%_rtl %retypePassword label,%_material-design[dir=rtl] %email label,%_material-design[dir=rtl] %password label,%_material-design[dir=rtl] %retypePassword label": "right:0;left:auto;text-align:right;transform-origin:right top;-webkit-transform-origin:right top;-ms-transform-origin:right top;",
    "%_material-design %email,%_material-design %password,%_material-design %retypePassword": "position:relative;margin-bottom:25px;",
    "%_material-design %email input:focus ~ label,%_material-design %email input:valid ~ label,%_material-design %email input:not([value=\"\"]) ~ label,%_material-design %password input:focus ~ label,%_material-design %password input:valid ~ label,%_material-design %password input:not([value=\"\"]) ~ label,%_material-design %retypePassword input:focus ~ label,%_material-design %retypePassword input:valid ~ label,%_material-design %retypePassword input:not([value=\"\"]) ~ label": "-webkit-transform:translate3d(0, 10px, 0) scale(0.75);transform:translate3d(0, 10px, 0) scale(0.75);",
    "%_material-design %email label,%_material-design %password label,%_material-design %retypePassword label": "display:initial !important;position:absolute;cursor:text;left:0;right:auto;bottom:100%;max-width:100%;height:26px;[fnt]    font-size:16px;text-align:left;color:[txt2];overflow:hidden;text-overflow:ellipsis;white-space:nowrap;-webkit-transform-origin:left top;transform-origin:left top;-webkit-transform:translate3d(0, 28px, 0) scale(1);transform:translate3d(0, 28px, 0) scale(1);transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);-webkit-transition:-webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);",
    "%_material-design %email input,%_material-design %password input,%_material-design %retypePassword input": "[fnt]    font-size:16px;border-top-width:0 !important;border-right-width:0 !important;border-left-width:0 !important;border-bottom:1px solid;border-color:[brd];height:26px !important;padding:2px;transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:hover:not(:focus),%_material-design %password input:hover:not(:focus),%_material-design %retypePassword input:hover:not(:focus)": "border-color:[brd_h];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email input:focus,%_material-design %password input:focus,%_material-design %retypePassword input:focus": "border-color:[txt];transition:border-color .2s ease-in-out;-moz-transition:border-color .2s ease-in-out;-webkit-transition:border-color .2s ease-in-out;",
    "%_material-design %email p,%_material-design %password p,%_material-design %retypePassword p": "[fnt]    font-size:12px !important;line-height:18px;width:100%;padding-top:3px;min-height:24px;padding-bottom:0;height:auto;",
    "%[data-state~=\"mobile\"] %dialog%_material-design": "width:280px !important;top:60px !important;left:calc(50% - 140px) !important;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %dialogTitle,%[data-state~=\"mobile\"] %dialog%_material-design %title": "margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %dialog%_material-design %subtitle": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %submitButton,%[data-state~=\"mobile\"] %okButton": "[fnt]  float:none;font-weight:normal;",
    "%[data-state~=\"mobile\"] %socialLoginWrapper": "width:100% !important;",
    "%[data-state~=\"mobile\"] %_material-design %_sectionDivider": "width:100%;",
    "%[data-state~=\"mobile\"] %_material-design %_wrapper": "height:auto;padding:0 0 20px;",
    "%[data-state~=\"mobile\"] %_material-design %email label,%[data-state~=\"mobile\"] %_material-design %password label,%[data-state~=\"mobile\"] %_material-design %retypePassword label": "padding-left:0;",
    "%dialog %title": "[ttlFnt]  font-size:36px;line-height:48px;",
    "%dialog %_wrapper": "max-width:518px;color:[defClr];",
    "%dialog %_wrapper %content": "margin-top:24px;margin-bottom:60px;",
    "%dialog %_wrapper %content %someInfoTxt,%dialog %_wrapper %content %checkTxt": "[cntFnt]      font-size:18px;line-height:30px;",
    "%dialog %_wrapper %_footer %okButton": "[btnFnt]",
    "%dialog %_wrapper %_footer %backToSiteLink": "[cntFnt]    color:[defClr];text-decoration:underline;font-size:18px;line-height:30px;margin-top:24px;display:inline-block;",
    "%dialog %_wrapper %_footer %backToSiteLink:hover": "opacity:0.8;color:[defClr];",
    "%dialog %_wrapper %_footer %backToSiteLink:active": "opacity:0.6;color:[defClr];",
    "%dialog %_wrapper %_footer %backToSiteLink:visited": "color:[defClr];",
    "%[data-state~=\"mobile\"] %dialog %title": "font-size:24px;line-height:34px;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper": "width:280px;padding:0;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper %content": "margin-top:18px;margin-bottom:60px;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper %content %someInfoTxt,%[data-state~=\"mobile\"] %dialog %_wrapper %content %checkTxt": "font-size:13px;line-height:22px;display:inline;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper %_footer %backToSiteLink": "font-size:13px;line-height:22px;margin-top:14px;"
  }
}
 skins['wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinNew'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {}
    ],
    [
      "div",
      "xButton",
      [],
      {},
      [
        "svg",
        null,
        [],
        {
          "viewBox": "0 0 180 180"
        },
        [
          "path",
          null,
          [],
          {
            "d": "M5 5 L175 175 M175 5 L5 175"
          }
        ]
      ]
    ],
    [
      "div",
      "dialog",
      [
        "_responsive"
      ],
      {},
      [
        "h3",
        "title",
        [],
        {}
      ],
      [
        "div",
        null,
        [
          "_wrapper"
        ],
        {},
        [
          "div",
          "content",
          [],
          {},
          [
            "div",
            "someInfoTxt",
            [],
            {}
          ],
          [
            "div",
            "checkTxt",
            [],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_footer"
          ],
          {},
          [
            "button",
            "okButton",
            [],
            {}
          ],
          [
            "a",
            "backToSiteLink",
            [],
            {
              "role": "link",
              "tabIndex": 0
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "inputWithValidation": {
      "skin": "wysiwyg.viewer.skins.InputWithValidationSkin"
    },
    "okButton": {
      "skin": "wysiwyg.viewer.skins.button.BasicButton",
      "styleGroup": "b1"
    }
  },
  "params": {
    "txt": "TEXT_COLOR",
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "clr": "COLOR",
    "secondary-color": "COLOR",
    "bg-clr": "COLOR",
    "headingL": "FONT",
    "ttlFnt": "FONT",
    "defClr": "COLOR",
    "cntFnt": "FONT",
    "btnFnt": "FONT",
    "bg-block": "BG_COLOR",
    "bg-border": "BG_COLOR"
  },
  "paramsDefaults": {
    "txt": "#9c9c9c",
    "bg": "#fff",
    "tdr": "BASE_THEME_DIRECTORY",
    "clr": "color_15",
    "secondary-color": "color_13",
    "bg-clr": "color_11",
    "headingL": "font_5",
    "ttlFnt": "font_5",
    "defClr": "color_15",
    "cntFnt": "font_7",
    "btnFnt": "font_8",
    "bg-block": "#555",
    "bg-border": "#aaa"
  },
  "paramsMutators": {
    "bg-block": {
      "type": "alpha",
      "value": 0.75
    },
    "bg-border": {
      "type": "alpha",
      "value": 0.7
    }
  },
  "css": {
    "%": "width:100%;height:100%;z-index:100001;font-size:1em;color:[txt];position:fixed !important;left:0;top:0;",
    "%[data-state~=\"mobile\"]": "width:100%;height:100%;z-index:100001;font-size:1em;overflow-y:scroll;color:[txt];",
    "%[data-state~=\"mobile\"] %dialog": "width:260px;left:calc(50% - 130px);position:absolute;padding:10px;top:20px;padding:0;top:calc(50% - 190px);width:280px;left:calc(50% - 140px);",
    "%[data-state~=\"mobile\"] %dialog:not(%_responsive) %_wrapper": "padding:10px 20px;",
    "%[data-state~=\"mobile\"] %_wrapper": "padding:10px 20px;padding:0 10px 20px;",
    "%[data-state~=\"mobile\"] %title": "font-size:14px;font-size:36px;",
    "%[data-state~=\"mobile\"] %infoTitle": "font-size:14px;margin-bottom:18px;",
    "%[data-state~=\"mobile\"] %cancel": "font-size:14px;line-height:30px;",
    "%[data-state~=\"mobile\"] %errMsg": "height:24px;height:20px;padding-bottom:20px;",
    "%[data-state~=\"mobile\"] %submitButton": "height:36px;font-size:14px;",
    "%blockingLayer": "background-color:[bg-block];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;background-color:[bg-clr];",
    "%dialog": "top:calc(50% - 250px);box-sizing:content-box;position:fixed;width:100%;text-align:center;",
    "%dialog:not(%_responsive)": "background-color:[bg-border];width:500px;left:calc(50% - 250px);position:fixed;padding:20px;box-sizing:border-box;visibility:hidden;",
    "%dialog:not(%_responsive) %_wrapper": "background-color:[bg];width:100%;height:100%;padding:20px 40px;box-sizing:border-box;",
    "%[data-state~=\"canBeClosed\"] %xButton": "position:absolute;top:-14px;right:-14px;cursor:pointer;background:transparent url([tdr]viewer_login_sprite.png) no-repeat right top;height:30px;width:30px;position:fixed;top:60px;right:60px;background:none;z-index:3;",
    "%[data-state~=\"canBeClosed\"] %xButton:hover": "background-position:right -80px;",
    "%note": "font-size:12px;text-align:right;padding-bottom:10px;float:right;margin-right:4px;margin-left:4px;font-weight:300;color:[clr];",
    "%switchDialogLink": "font-size:12px;color:#0198ff;border-bottom:1px solid #0198ff;float:right;",
    "%switchDialogLink:hover": "color:#04f;border-bottom-color:#04f;",
    "%title": "font-size:25px;font-weight:bold;color:#555;padding-bottom:15px;",
    "%infoTitle": "color:#0198ff;font-size:18px;margin-bottom:28px;margin-top:10px;font-weight:normal;",
    "%_footer": "width:100%;padding-bottom:30px;",
    "%[data-state~=\"canBeClosed\"] %_footer": "padding-bottom:10px;",
    "%cancel": "color:[txt];font-size:18px;text-decoration:underline;line-height:36px;float:left;",
    "%cancel:hover": "color:#9c3c3c;",
    "%errMsg": "font-size:12px;color:#d74401;height:34px;margin-top:5px;height:24px;margin-top:-24px;display:block;font-family:Helvetica;font-size:12px;line-height:24px;font-weight:300;color:#ff3d3d;text-align:left;  padding-top:0;",
    "%submitButton": "display:block;margin-left:auto;margin-right:0;cursor:pointer;border:solid 2px #0064a8;height:36px;width:143px;background:transparent url([tdr]viewer_login_sprite.png) repeat-x right -252px;color:#fff;font-size:24px;font-weight:bold;",
    "%submitButton:hover": "background-position:right -352px;border-color:#004286;",
    "%description": "float:none;font-size:15px;font-weight:bold;line-height:25px;color:#585858;margin-bottom:10px;",
    "@-moz-document url-prefix()": ":invalid",
    "": "box-shadow:none;",
    ":-moz-submit-invalid": "box-shadow:none;",
    ":-moz-ui-invalid": "box-shadow:none;",
    "%_wrapper": "background:transparent;color:[clr];font-family:Helvetica;",
    "%contentWrapper%_vertical %socialLoginWrapper,%contentWrapper%_vertical %divider": "display:none;",
    "%contentWrapper%_horizontal %socialLoginIframe": "width:100%;opacity:0;-webkit-transition:opacity ease-in 1s;transition:opacity ease-in 1s;",
    "%contentWrapper%_horizontal %socialLoginIframe%_ready": "opacity:1;",
    "%contentWrapper%_horizontal %socialLoginLoader::before,%contentWrapper%_horizontal %socialLoginLoader::after": "color:[clr];",
    "%[data-state~=\"desktop\"] %_wrapper": "width:450px;display:inline-block;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal": "width:800px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin:auto;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %_customLogin": "width:50%;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %divider": "border-right:1px solid [secondary-color];margin:-20px 66px;",
    "%[data-state~=\"desktop\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:50%;",
    "%[data-state~=\"desktop\"] %content%_validationError input": "border:1px solid #ff3d3d;",
    "%[data-state~=\"previewMode\"]:not([data-state~=\"mobile\"]) %blockingLayer": "position:fixed !important;",
    "%preloader": "display:none;",
    "%[data-state~=\"canBeClosed\"] %xButton svg": "width:25px;height:25px;stroke:[clr];stroke-width:15;cursor:pointer;pointer-events:none;",
    "%submitButton,%okButton": "height:44px;width:100%;border:0;font-size:14px;font-weight:300;line-height:44px;padding:0;",
    "%dialogTitle,%title": "[headingL]  line-height:.8em;padding-top:20px;font-weight:normal;font-size:56px;text-align:center;padding-bottom:0;color:[clr];",
    "%title%_single": "padding-bottom:85px;",
    "%dialogDescription,%subtitle": "font-family:Helvetica;font-size:16px;line-height:16px;font-weight:300;color:[clr];text-align:center;",
    "%subtitle": "margin-top:24px;padding-bottom:80px;",
    "%dialogDescription": "padding-bottom:35px;",
    "%[data-state~=\"mobile\"] %content%_validationError input": "border-bottom:1px solid #ff3d3d;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal": "height:275px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %socialLoginWrapper": "position:relative;width:240px;margin-top:10px;margin-bottom:120px;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchToEmailLink": "font-size:14px;line-height:14px;text-decoration:underline;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %switchDialogLink,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %note": "float:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_horizontal %email label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %password label,%[data-state~=\"mobile\"] %contentWrapper%_horizontal %retypePassword label": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %_customLogin": "display:none;",
    "%[data-state~=\"mobile\"] %contentWrapper%_socialLoginMode %socialLoginWrapper": "width:100%;",
    "%[data-state~=\"mobile\"] %contentWrapper%_emailLoginMode %_customLogin": "width:100%;",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design)%_emailLoginMode %socialLoginWrapper": "display:none;",
    "%[data-state~=\"mobile\"] %dialogTitle,%[data-state~=\"mobile\"] %title": "line-height:.8em;",
    "%[data-state~=\"mobile\"] %dialogTitle": "font-size:30px;",
    "%[data-state~=\"mobile\"] %title%_single": "padding-bottom:50px;",
    "%[data-state~=\"mobile\"] %dialogDescription,%[data-state~=\"mobile\"] %subtitle": "font-size:14px;line-height:14px;",
    "%[data-state~=\"mobile\"] %dialogDescription": "padding-bottom:12px;",
    "%[data-state~=\"mobile\"] %subtitle": "margin-top:18px;padding-bottom:44px;",
    "%[data-state~=\"mobile\"] %okButton": "height:40px;line-height:40px;",
    "%[data-state~=\"mobile\"] %forgotYourPasswordLinkMobile": "font-size:14px;line-height:14px;padding-top:24px;",
    "%[data-state~=\"mobile\"] %_switchToSignUpContainer": "padding-top:60px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton": "position:fixed;z-index:3;top:30px;right:30px;width:20px;height:20px;",
    "%[data-state~=\"mobile\"][data-state~=\"canBeClosed\"] %xButton svg": "width:20px;height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"][data-state~=\"previewMode\"] %xButton": "position:absolute;",
    "%[data-state~=\"mobile\"] %switchToSocialLink": "position:fixed;z-index:3;top:30px;left:30px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink svg": "height:20px;stroke-width:12px;",
    "%[data-state~=\"mobile\"] %switchToSocialLink%_socialLoginMode": "display:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer": "color:[clr];font-size:14px;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %switchDialogLink": "float:none;text-decoration:none;",
    "%[data-state~=\"mobile\"] %_switchDialogContainer %note": "float:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper": "position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;position:relative;display:none;-webkit-align-self:center;align-self:center;font-size:13px;margin-bottom:10px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox": "float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;float:left;margin-top:5px;margin-left:0;width:12px;opacity:0;cursor:pointer;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:4px;left:0;pointer-events:none;display:block;position:absolute;top:4px;left:0;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];fill:[clr];",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_checked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;display:none;",
    "%[data-state~=\"mobile\"] %communityCheckboxWrapper %joinCommunityByDefaultCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;display:block;",
    "%[data-state~=\"mobile\"] %_form-footer": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-top:25px;line-height:25px;text-align:center;color:[clr];font-weight:300;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreParagraph": "font-size:13px;max-width:300px;margin:auto auto 10px;text-align:center;",
    "%[data-state~=\"mobile\"] %contentWrapper": "padding-bottom:0;",
    "%[data-state~=\"mobile\"] %checkboxWrapper": "-webkit-box-flex:0;-webkit-flex-grow:0;flex-grow:0;-webkit-flex-shrink:0;flex-shrink:0;-webkit-flex-basis:auto;flex-basis:auto;-webkit-align-self:center;align-self:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;",
    "%[data-state~=\"mobile\"] %joinCommunityByDefaultCheckboxLabel": "display:inline-block;",
    "%[data-state~=\"mobile\"] %joinCommunityReadMoreLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %signupAgreement": "margin-right:4px;",
    "%[data-state~=\"mobile\"] %codeOfConductLink": "margin-left:4px;",
    "%[data-state~=\"mobile\"] %andElement": "margin-right:4px;margin-left:4px;",
    "%[data-state~=\"mobile\"] %policiesFooter": "display:inline-block;-webkit-align-self:center;align-self:center;font-size:13px;",
    "%email input,%password input,%retypePassword input": "padding-left:0;height:48px;font-size:32px;line-height:32px;background-color:transparent;border:1px solid [secondary-color];border-radius:0;-webkit-appearance:none;color:[clr];",
    "%[data-state~=\"mobile\"] %dialog:not(%_material-design) %email input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %password input,%[data-state~=\"mobile\"] %dialog:not(%_material-design) %retypePassword input": "background:none;border:none;border-radius:0;border-bottom:solid 1px;color:[clr];",
    "%switchDialogLink,%forgotYourPasswordLink,%note": "font-family:Helvetica;font-size:14px;  line-height:14px;  border:none;text-align:left;",
    "%_switchLinkContainer": "padding-top:13px;",
    "%_dialog-link": "color:[clr];cursor:pointer;font-weight:bold;",
    "%_dialog-link:hover": "color:[clr];text-decoration:underline;",
    "%footer": "padding-bottom:0;",
    "%socialLoginErrorMsg": "display:none;font-family:Helvetica;font-size:14px;line-height:14px;font-weight:300;color:#ff3d3d;",
    "%socialLoginErrorMsg%_enabled": "display:block;",
    "%[data-state~=\"mobile\"] %socialLoginErrorMsg": "margin-bottom:30px;",
    "%checkboxWrapper": "position:relative;",
    "%checkboxWrapper %rememberMeCheckbox": "float:left;margin-right:14px;margin-top:5px;margin-left:0;width:12px;position:relative;opacity:0;cursor:pointer;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox": "pointer-events:none;display:block;position:absolute;top:0;left:0;",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-mark": "fill:[clr];",
    "%checkboxWrapper %rememberMeCheckbox ~ label %_checkbox %_checkbox-bg": "fill:none;stroke:[secondary-color];stroke-width:2px;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_checked %_checkbox": "display:block;",
    "%checkboxWrapper %rememberMeCheckbox:checked ~ label%_unchecked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_checked %_checkbox": "display:none;",
    "%checkboxWrapper %rememberMeCheckbox:not(checked) ~ label%_unchecked %_checkbox": "display:block;",
    "%[data-state~=\"dialogLoader\"] %dialog::after": "height:100%;width:100%;position:fixed;left:0;top:0;background-color:[bg-clr];opacity:.6;content:' ';",
    "%[data-state~=\"dialogLoader\"] %preloader": "display:block;",
    "%[data-state~=\"dialogLoader\"] %preloader::before,%[data-state~=\"dialogLoader\"] %preloader::after": "color:[clr];",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper": "-webkit-box-pack:center !important;-webkit-justify-content:center !important;justify-content:center !important;",
    "%[data-state~=\"wixInternalAuth\"] %contentWrapper %_customLogin,%[data-state~=\"wixInternalAuth\"] %contentWrapper %emailSectionDivider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %divider,%[data-state~=\"wixInternalAuth\"] %contentWrapper %switchToEmailLink": "display:none;",
    "%[data-state~=\"wixInternalAuth\"] %_switchDialogContainer": "display:none;",
    "%dialog %title": "[ttlFnt]  font-size:36px;line-height:48px;",
    "%dialog %_wrapper": "max-width:518px;color:[defClr];",
    "%dialog %_wrapper %content": "margin-top:24px;margin-bottom:60px;",
    "%dialog %_wrapper %content %someInfoTxt,%dialog %_wrapper %content %checkTxt": "[cntFnt]      font-size:18px;line-height:30px;",
    "%dialog %_wrapper %_footer %okButton": "[btnFnt]",
    "%dialog %_wrapper %_footer %backToSiteLink": "[cntFnt]    color:[defClr];text-decoration:underline;font-size:18px;line-height:30px;margin-top:24px;display:inline-block;",
    "%dialog %_wrapper %_footer %backToSiteLink:hover": "opacity:0.8;color:[defClr];",
    "%dialog %_wrapper %_footer %backToSiteLink:active": "opacity:0.6;color:[defClr];",
    "%dialog %_wrapper %_footer %backToSiteLink:visited": "color:[defClr];",
    "%[data-state~=\"mobile\"] %dialog %title": "font-size:24px;line-height:34px;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper": "width:280px;padding:0;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper %content": "margin-top:18px;margin-bottom:60px;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper %content %someInfoTxt,%[data-state~=\"mobile\"] %dialog %_wrapper %content %checkTxt": "font-size:13px;line-height:22px;display:inline;",
    "%[data-state~=\"mobile\"] %dialog %_wrapper %_footer %backToSiteLink": "font-size:13px;line-height:22px;margin-top:14px;"
  }
}

        return skins;
    }));