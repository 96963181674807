define(['lodash',
    'componentsCore',
    'dialogs/dialogMixin/dialogMixin',
    'prop-types'
], function (_, componentsCore, dialogMixin, PropTypes) {
    'use strict';

    /**
   * @class components.siteMembersDialogs.SentConfirmationEmailDialog
   * @extends {core.skinBasedComp}
   * @extends {ReactCompositeComponent}
   * @property {comp.properties} props
   */
    const sentConfirmationEmailDialog = {
        displayName: 'SentConfirmationEmailDialog',
        mixins: [
            componentsCore.mixins.skinBasedComp,
            dialogMixin,
            componentsCore.mixins.cyclicTabbingMixin
        ],

        propTypes: {
            language: PropTypes.string,
            onResendLinkClick: PropTypes.func
        },

        getSkinProperties() {
            if (this.shouldDialogBeClosed()) {
                return this.getCloseDialogSkinProperties();
            }

            const lang = this.props.language;
            const direction = this.props.direction;

            const sentEmailTxt = this.getText(lang, 'SMEmailVerification_EmailConfirmationSent');
            const checkInboxTxt = this.getText(lang, 'SMEmailVerification_CheckYourInbox').replace('&#39;', "'");
            const didntGetTxt = this.getText(lang, 'SMEmailVerification_DidntGetEmail').replace('&#39;', "'");
            const checkSpamTxt = `${this.getText(lang, 'SMEmailVerification_CheckSpam')} `;
            const resendLinkTxt = this.getText(lang, 'SMEmailVerification_ResendLink');

            return {
                'blockingLayer': {},
                'dialog': {
                    dir: direction
                },
                'sentEmail': {
                    children: sentEmailTxt
                },
                'checkInbox': {
                    children: checkInboxTxt
                },
                'didntGetEmail': {
                    children: didntGetTxt
                },
                'checkSpam': {
                    children: checkSpamTxt
                },
                'resendLink': {
                    children: resendLinkTxt,
                    onClick: this.props.onResendLinkClick,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'xButton': this.createXButton(),
                '': {
                    'aria-labelledby': `${this.props.id}sentEmail`,
                    onKeyDown: this.preventTabbingOut,
                    role: 'dialog',
                    style: {
                        //restore pointer events after inlineContent of page component, a parent of popupContainer,
                        //got pointerEvents none to make possible click events on overlay
                        pointerEvents: 'auto'
                    },
                    dir: direction,
                    tabIndex: -1
                }
            };
        },

        componentDidMount() {
            this.props.onDialogReady();
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.SentConfirmationEmailDialog',
        sentConfirmationEmailDialog);

    return sentConfirmationEmailDialog;
});
