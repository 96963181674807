define([
    'santa-components',
    'componentsCore',
    'dialogs/dialogMixin/dialogMixin',
    'dialogs/utils/dialogCustomCss',
    'prop-types'
], function (santaComponents, componentsCore, dialogMixin, getCustomCompCss, PropTypes) {
    'use strict';

    /**
     * @class components.siteMembersDialogs.ResetPasswordDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const resetPasswordDialog = {
        displayName: 'ResetPasswordDialog',
        mixins: [
            componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss}),
            dialogMixin,
            componentsCore.mixins.cyclicTabbingMixin
        ],

        propTypes: {
            forgotPasswordToken: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.forgotPasswordToken,
            language: PropTypes.string,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
        },

        passwordInputRef: 'passwordInput',
        retypePasswordInputRef: 'retypePasswordInput',

        validateBeforeSubmit() {
            this.refs[this.passwordInputRef].validate();
            this.refs[this.retypePasswordInputRef].validate();

            if (!this.refs[this.passwordInputRef].isValid() || !this.refs[this.retypePasswordInputRef].isValid()) {
                return false;
            }

            if (this.refs[this.passwordInputRef].getValue() !== this.refs[this.retypePasswordInputRef].getValue()) {
                this.setErrorMessage('SMForm_Error_Password_Retype');
                return false;
            }

            return true;
        },

        getDefaultProps() {
            return {
                shouldNotClose: true
            };
        },

        getDataToSubmit() {
            return {
                forgotPasswordToken: this.props.forgotPasswordToken,
                newPassword: this.refs[this.passwordInputRef].getValue()
            };
        },

        getFocusElement() {
            return this.refs[this.passwordInputRef].refs.input;
        },

        getSkinProperties() {
            const lang = this.props.language;
            const direction = this.props.direction;
            const titleText = this.getText(lang, 'SMResetPassMail_title');
            const descriptionText = this.getText(lang, 'dialogMixinTranslations_RESET_PASSWORD_TEXT');
            const submitButtonText = this.getText(lang, 'dialogMixinTranslations_RESET_PASSWORD_BUTTON');
            const newPasswordText = this.getText(lang, 'SMResetPass_New_Password');
            const retypePasswordText = this.getText(lang, 'dialogMixinTranslations_RESET_PASSWORD_NEWFIELD_RETYPE');
            const errMsgText = this.getErrorMessage();
            return {
                'blockingLayer': {},
                'dialog': {
                    dir: direction
                },
                'title': {
                    children: titleText
                },
                'errMsg': {
                    children: errMsgText
                },
                'content': this.createContent(),
                'okButton': {
                    children: submitButtonText,
                    onClick: this.submit,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'subtitle': {
                    children: descriptionText
                },
                'password': {
                    children: this._createPasswordField({
                        enableLowerLabel: true,
                        placeholder: newPasswordText
                    })
                },
                'retypePassword': {
                    children: this._createRetypePasswordField({
                        enableLowerLabel: true,
                        placeholder: retypePasswordText
                    })
                },
                '': {
                    onKeyDown: this.preventTabbingOut,
                    role: 'dialog',
                    style: {
                        //restore pointer events after inlineContent of page component, a parent of popupContainer,
                        //got pointerEvents none to make possible click events on overlay
                        pointerEvents: 'auto'
                    },
                    dir: direction,
                    tabIndex: -1
                }
            };
        },

        _createPasswordField(extraProps) {
            const passwordProperties = {
                refId: this.passwordInputRef,
                inputTitleKey: 'SMResetPass_New_Password',
                language: this.props.language
            };
            return this.createPasswordInput(passwordProperties, extraProps);
        },

        _createRetypePasswordField(extraProps) {
            const retypePasswordProperties = {
                refId: this.retypePasswordInputRef,
                inputTitleKey: 'SMResetPass_Retype_Password',
                language: this.props.language
            };
            return this.createPasswordInput(retypePasswordProperties, extraProps);
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.ResetPasswordDialog', resetPasswordDialog);

    return resetPasswordDialog;
});
