define([
    'santa-components',
    'lodash',
    'componentsCore',
    'prop-types',
    'dialogs/dialogMixin/dialogMixin',
    'dialogs/utils/dialogCustomCss'
], function (
    santaComponents,
    _,
    componentsCore,
    PropTypes,
    dialogMixin,
    getCustomCompCss
) {
    'use strict';

    /**
     * @class components.siteMembersDialogs.EnterPasswordDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const enterPasswordDialog = {
        displayName: 'EnterPasswordDialog',
        mixins: [componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss}), dialogMixin],

        propTypes: {
            digestedPassword: PropTypes.string,
            language: PropTypes.string,
            notClosable: PropTypes.bool,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
        },
        passwordInputRef: 'passwordInput',

        validateBeforeSubmit() {
            return this.refs[this.passwordInputRef].validate();
        },

        getDataToSubmit() {
            // digestedPassword not provided => password validation is performed from outside (user API for example)
            return this.props.digestedPassword ? {} : {password: this.refs[this.passwordInputRef].getValue()};
        },

        getFocusElement() {
            return this.refs[this.passwordInputRef].refs.input;
        },

        getSkinProperties() {
            const submitButtonText = this.getText(this.props.language, 'SMRegister_GO');
            const errMsgText = this.getErrorMessage();

            const skin = {
                blockingLayer: {},
                'wrapper': {
                    className: `${this.props.styleId}_${this.props.direction ? this.props.direction : 'ltr'}`
                },
                'errMsg': {
                    children: errMsgText
                },
                'content': this.createContent(),
                'subtitle': {
                    children: this.getText(this.props.language, 'dialogMixinTranslations_GUEST_LOGIN_SUBTITLE')
                },
                'title': {
                    children: this.getText(this.props.language, 'dialogMixinTranslations_GUEST_LOGIN_TITLE')
                },
                'okButton': {
                    children: submitButtonText,
                    onClick: this.submit
                },
                'password': {
                    children: this._createPasswordField({
                        enableLowerLabel: true,
                        placeholder: this.getText(this.props.language, 'PasswordLogin_Password')
                    })
                }
            };

            if (!this.props.notClosable) {
                const closeableSkinProperties = {
                    'blockingLayer': {},
                    'xButton': this.createXButton()
                };
                _.merge(skin, closeableSkinProperties);
            }

            return skin;
        },

        _validatePassword(password) {
            if (!password) {
                return this.getText(this.props.language, 'SMForm_Error_Password_Blank');
            }
            return false;
        },

        /**
         *
         * @returns {*}
         * @private
         */
        _createPasswordField(extraProps) {
            const passwordProperties = {
                refId: this.passwordInputRef,
                inputTitleKey: 'PasswordLogin_Password',
                language: this.props.language,
                overrideValidators: [this._validatePassword]
            };
            return this.createPasswordInput(passwordProperties, extraProps);
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.EnterPasswordDialog', enterPasswordDialog);

    return enterPasswordDialog;
});
