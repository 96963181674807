define([
    'lodash',
    'dialogs/dialogMixin/dialogMixin',
    'prop-types',
    'componentsCore'
], function (_, dialogMixin, PropTypes, componentsCore) {
    'use strict';

    /**
     * @class components.siteMembersDialogs.EmailVerificationDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const emailVerificationDialog = {
        displayName: 'EmailVerificationDialog',
        mixins: [
            componentsCore.mixins.skinBasedComp,
            dialogMixin,
            componentsCore.mixins.cyclicTabbingMixin
        ],

        propTypes: {
            language: PropTypes.string,
            onResendLinkClick: PropTypes.func
        },


        getSkinProperties() {
            if (this.shouldDialogBeClosed()) {
                return this.getCloseDialogSkinProperties();
            }

            const lang = this.props.language;
            const direction = this.props.direction;

            const titleTxt = this.getText(lang, 'SMEmailVerification_Title');
            const thanksTxt = `${this.getText(lang, 'SMEmailVerification_Thanks')} `;
            const clickTheLinkTxt = this.getText(lang, 'SMEmailVerification_ClickTheLink');
            const didntGetTxt = this.getText(lang, 'SMEmailVerification_DidntGetEmail').replace('&#39;', "'");
            const checkSpamTxt = `${this.getText(lang, 'SMEmailVerification_CheckSpam')} `;
            const resendLinkTxt = this.getText(lang, 'SMEmailVerification_ResendLink');

            return {
                'blockingLayer': {},
                'dialog': {
                    dir: direction
                },
                'title': {
                    children: titleTxt
                },
                'thanksTxt': {
                    children: thanksTxt
                },
                'clickTheLinkTxt': {
                    children: clickTheLinkTxt
                },
                'didntGetTxt': {
                    children: didntGetTxt
                },
                'checkSpamTxt': {
                    children: checkSpamTxt
                },
                'resendLink': {
                    children: resendLinkTxt,
                    onClick: this.props.onResendLinkClick,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'xButton': this.createXButton(),
                '': {
                    'aria-labelledby': `${this.props.id}title`,
                    onKeyDown: this.preventTabbingOut,
                    role: 'dialog',
                    style: {
                        //restore pointer events after inlineContent of page component, a parent of popupContainer,
                        //got pointerEvents none to make possible click events on overlay
                        pointerEvents: 'auto'
                    },
                    dir: direction,
                    tabIndex: -1
                }
            };
        },

        componentDidMount() {
            this.props.onDialogReady();
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.EmailVerificationDialog',
        emailVerificationDialog);

    return emailVerificationDialog;
});
